<template>
    <v-container>
      <v-card class="mx-auto my-5" max-width="1000" style=" color: #333;font-family: 'cairo_reg', sans-serif !important;
   ;    background-color: #fff;">
        <!-- Title Section -->
        <v-card-title class="text-h4 text-center" style="color: #b8860b; font-family: 'cairo_reg', sans-serif !important; font-size: 23px !important;    color: #d4af37;
    font-weight: bold  !important">
          جائزة الساقي للرواية العربية
        </v-card-title>
  
        <!-- Video Section -->
        <v-responsive aspect-ratio="16/9" class="my-4">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/YOUR_VIDEO_ID" 
            title="Award Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-responsive>
  
        <!-- Text Section -->
        <v-card-text style="font-family: 'cairo_reg', sans-serif;">
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37; font-family: 'cairo_reg', sans-serif !important;    font-size: 22px !important;">التعريف:</v-card-subtitle>
          <v-divider></v-divider>
          <p>
            جائزة الساقي للرواية العربية  هي جائزة سنوية تطلقها العتبة العباسية المقدسة، لتواكب معطيات الرواية العالمية...
          </p>
  
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37;  font-family: 'cairo_reg', sans-serif !important;    font-size: 22px !important;">الرسالة والأهداف:</v-card-subtitle>
          <v-divider></v-divider>
          <p>
            تأسست فكرة هذه الجائزة، بالاستناد إلى توجيهات المتولي الشرعي للعتبة العباسية المقدسة السيد أحمد الصافي "دام عزّه"...
          </p>
  
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37; font-family: 'cairo_reg', sans-serif !important;    font-size: 22px !important;">شروط وآليات الترشُح:</v-card-subtitle>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>أن يكون النص مكتوباً باللغة العربية.</v-list-item>
            <v-list-item>أن لا يكون النص منشوراً أو مشاركاً به بمسابقة أخرى.</v-list-item>
            <v-list-item>تكون المشاركة بعمل واحد، وفي مجال واحد فقط.</v-list-item>
            <v-list-item>يحق للفائزين المشاركة بعد مرور دورتين على فوزهم.</v-list-item>
          </v-list>
  
          <p>ترسل الأعمال المشاركة بصيغة PDF إلى:</p>
          <v-btn text color="primary" href="mailto:al-saqi.aaf@alkafeel.net">al-saqi.aaf@alkafeel.net</v-btn>
  
          <!-- <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37;">لجان الجائزة:</v-card-subtitle>
          <v-divider></v-divider>
          <p>تتكون الجائزة من أربع لجان...</p>
   -->
          <v-divider></v-divider>
          <v-card-subtitle class="text-h5" style="color: #d4af37; font-family: 'cairo_reg', sans-serif !important;    font-size: 22px !important;">المكافآت:</v-card-subtitle>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>المركز الأول: 15 مليون دينار عراقي.</v-list-item>
            <v-list-item>المركز الثاني: 10 مليون دينار عراقي.</v-list-item>
            <v-list-item>المركز الثالث: 7 مليون دينار عراقي.</v-list-item>
          </v-list>
  
          <p>جرى الإعلان عن الجائزة في ايلول/ 2024م. ويكون آخر يوم لاستلام المخطوطات المشاركة هو 10/4/ 2024 م.</p>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  

<script>
export default {
  name: 'AwardDetails'
}
</script>
<style scoped>
p {
  margin: 16px 0;
}

.v-card-title, .v-card-subtitle {
  font-family: 'cairo_reg', sans-serif !important;
}

/* Custom colors using golden shades */
.v-card-title {
  color: #b8860b; /* Dark Goldenrod */
}

.v-card-subtitle {
  color: #d4af37; /* Gold */
}

.v-card {
  background-color: #fdf5e6; /* Old Lace */
}

.v-btn {
  color: #b8860b !important; /* Dark Goldenrod */
}
</style>